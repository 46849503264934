.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.headerButton {
  padding: 10px;
  border: none;
  background-color: #1b305a;
  color: white;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s;
}

.headerButton:hover {
  background-color: #0056b3;
}

/* Estilo para o select sempre visível */
.customSelect {
  min-width: 150px;
  padding: 10px;
  border: 2px solid #1b305a;
  appearance: none; /* Remove a seta padrão */
  background-color: #f0f0f0;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  transition: border-color 0.3s ease;
}

/* Estilo para o hover e focus do select */
.customSelect:hover,
.customSelect:focus {
  border-color: #1b305a;
}

/* Remove a seta padrão do select no IE */
.customSelect::-ms-expand {
  display: none;
}
