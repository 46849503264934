/* Modal styling */
.modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalContent {
  position: relative;
  width: 300px;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  text-align: center;
}

.modalContent ul {
  padding: 0;
  list-style: none;
}

.modalContent button {
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
}

/* Event items */
.eventItem {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.eventItem:last-child {
  border-bottom: none;
}

/* Responsiveness */
@media (max-width: 768px) {
  .modalContent {
    width: 90%;
  }
}
