.day,
.empty {
  padding: 10px;
  background-color: #f0f0f0;
  text-align: center;
}

.day {
  cursor: pointer;
  transition: background-color 0.3s;
}

.highlighted {
  border: 2px solid red;
  background-color: #ffcccb;
}

.daysOfWeek {
  display: grid;
  margin-bottom: 10px;
  font-weight: bold;
  grid-template-columns: repeat(7, 1fr);
}

.days {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(7, 1fr);
}
