.calendar {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  text-align: center;
}

.currentMonth {
  padding: 0 10px;
  border: 2px solid #007bff;
  border-radius: 8px;
  font-size: 1.5rem;
  font-weight: bold;
}

.empty {
  background-color: transparent;
}

.day:hover {
  background-color: #bde0fe;
}

/* Responsiveness */
@media (max-width: 768px) {
  .calendar {
    max-width: 100%;
    padding: 0 10px;
  }

  .modalContent {
    width: 90%;
  }
}
