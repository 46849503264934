#footer {
  .footer {
    #footer-navigation {
      h3.section {
        // min-height: 3.5rem;
        margin-bottom: 0;

        a {
          font-size: 15px;
        }
      }

      ul.item-wrapper {
        li.nav-subitem {
          a {
            font-size: 15px;
          }
        }
      }
    }

    .logo {
      img {
        width: 150px !important;
      }
    }

    .about {
      max-width: 600px;
      margin: $spacing-medium auto;
      text-align: left;
      @include add(size, xs);
      @include add(weight, light);

      .label {
        @include add(weight, semi-bold);
      }
    }

    .a.container.narrow {
      max-width: initial;
    }

    .container.footer {
      .navigation {
        .navigation-grid {
          .navigation-item {
            border-left-width: 1px !important;
          }
        }
      }
    }
  }
}

#main {
  .header-wrapper {
    .header {
      .logo-nav-wrapper {
        padding-top: 0px;
        padding-bottom: 0px;
        background-color: initial;

        .navigation {
          flex: initial;
          color: #555555 !important;

          .item {
            font-size: 12px;
          }

          ul.desktop-menu {
            > li:not(:last-child) {
              border-right: none;
            }

            > li > button {
              margin: 0 5px 0 5px;
              color: #555555 !important;
            }

            > li > a {
              margin: 0 5px 0 5px;
              color: #555555 !important;
            }

            > li:first-child > button {
              margin-left: 0;
            }
          }
        }

        .logo {
          flex: 0;
        }
      }

      .tools-wrapper {
        display: none;
        height: 0px;
        padding-top: 0px;
      }
    }
  }
}

.q.container.barra-wrapper .barra {
  background-color: #1b305a !important;
}

.blocks-group-wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
}

.custom-login-style {
  width: 100% !important;
}

.lead-image-slot {
  flex: 0.3;
  margin-top: 2rem;
}

.has-toolbar .content-area #view {
  display: flex;
}

.has-toolbar .content-area #page-document {
  flex: 0.8;
}

.custom-login-other-style {
  display: none !important;
}

.ui.container.comments {
  display: none;
}
